import paronApi from '@/utils/paron-api'
export default {
    getAllMembers(page) {
        var path = page ? `member/all?page=${page}&per_page=10` : 'member/all'
        return paronApi.get(path)
    },
    getMemberById(id) {
        return paronApi.get(`member/${id}`)
    },

    createMember(data) {
        return paronApi.post('member/new', data)
    },

    editMemberById(id, data) {
        return paronApi.post(`member/${id}/edit`, data)
    },

    deleteMemberById(id) {
        return paronApi.delete(`member/${id}`)
    },
    getMembersByFilter(page, filter, sortOption, order) {
        var path = page ? `member/filtered?page=${page}&per_page=10&` : 'member/filtered?per_page=10'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    },
    toggleApproved(id) {
        return paronApi.post(`member/${id}/toggleApprove`)
    },
    importFromFile(formData) {
        return paronApi.uploadFile(`member/upload`, formData)
    }

}
