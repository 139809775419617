<template>
    <v-container class="container">
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <div>
                <v-text-field
                    v-model="voucher.description"
                    label="Description"
                    :clearable="clearable"
                />
            </div>
            <div class="selection-form">
                <v-autocomplete
                    v-model="voucher.team"
                    :items="teams"
                    item-text="title"
                    menu-props="auto"
                    label="Select Team"
                    hide-details
                    prepend-icon="mdi-account-group"
                    single-line
                    :clearable="!readOnly"
                    return-object
                    deletable-chips
                    chips
                />
                <v-autocomplete
                    v-model="voucher.member"
                    :items="members"
                    :item-text="getMemberInfo"
                    menu-props="auto"
                    label="Select Member"
                    hide-details
                    prepend-icon="mdi-account"
                    single-line
                    :clearable="!readOnly"
                    return-object
                    deletable-chips
                    chips
                />
            </div>
            <div v-if="mode !== 'show'" class="submit-button-wrapper">
                <v-btn class="my-5 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                    Submit
                </v-btn>
            </div>
        </v-form>
        <div>
            <div class="selected-values">
                <div v-if="voucher.team">
                    <h2> Team</h2>
                    <p v-if="voucher.team.title"> Title: {{ voucher.team.title }} </p>
                </div>
                <div v-if="voucher.member">
                    <h2> Member</h2>
                    <p v-if="voucher.member.firstName">First Name: {{ voucher.member.firstName }} </p>
                    <p v-if="voucher.member.lastName">Last Name: {{ voucher.member.lastName }} </p>
                    <p v-if="voucher.member.birthDate">Birthdate: {{ formatDate(voucher.member.birthDate, 'yyyy-MM-DD') }} </p>
                    <p v-if="voucher.member.cards.length > 0">
                        <span v-for="(card, index) in voucher.member.cards" :key="index">
                            <div v-if="card.active"> Active Card: {{ card.number }} </div>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import teamService from '@/services/team-service'
    import memberService from '@/services/member-service'
    import voucherService from '@/services/voucher-service'
    export default {
        name: 'VoucherForm',
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                voucherId: null,
                voucher: {
                    description: '',
                    member: null,
                    team: null
                },
                members: [],
                teams: []
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllMembersAndTeams()
            if (this.mode === 'create') {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.voucherId = this.$route.params.voucherId
            if (this.voucherId) {
                voucherService.getVoucherById(this.voucherId).then(resp => {
                    this.voucher = resp.data.voucher
                })
            }
        },
        methods: {
            shouldDisableSubmit() {
                return !this.voucher.member || !this.voucher.team
            },
            onEdit() {
                this.$router.push(`/vouchers/${this.voucherId}/edit?page=${this.$route.query.page}`)
            },
            onSubmit() {
                this.prepareVoucherFields()
                if (this.mode === 'edit') {
                    voucherService.editVoucherById(this.voucherId, this.voucher).then(() => {
                        this.$router.push(`/vouchers?page=${this.$route.query.page}`)
                    })
                } else if (this.mode === 'create') {
                    voucherService.createVoucher(this.voucher).then(() => {
                        this.$router.push(`/vouchers?page=${this.$route.query.page}`)
                    })
                }
            },
            prepareVoucherFields() {
                this.voucher.member = this.voucher.member.id
                this.voucher.team = this.voucher.team.id
            },
            fetchAllMembersAndTeams() {
                teamService.getAllTeams().then((resp) => {
                    this.teams = resp.data.data
                })
                memberService.getAllMembers().then((resp) => {
                    this.members = resp.data.data
                })
            },
            formatDate(date, format) {
                return moment(date).format(format)
            },
            getMemberInfo(member) {
                let cardString = ''
                const memberCards = member.cards.filter(card => { return card.active }).map(card => card.number)
                if (memberCards.length > 0) {
                    cardString = ' [' + memberCards.join() + ']'
                }
                return member.firstName + ' ' + member.lastName + cardString
            }
        }
    }
</script>

<style scoped>

.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.selection-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.selected-values {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.v-select {
    max-width: 500px;
}
</style>
