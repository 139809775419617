import paronApi from '@/utils/paron-api'

export default {
    getAllVouchers(page) {
        var path = page ? `voucher/all?page=${page}&per_page=10` : 'voucher/all'
        return paronApi.get(path)
    },

    getVoucherById(id) {
        return paronApi.get(`voucher/${id}`)
    },

    createVoucher(data) {
        return paronApi.post('voucher/new', data)
    },

    editVoucherById(id, data) {
        return paronApi.post(`voucher/${id}/edit`, data)
    },

    deleteVoucherById(id) {
        return paronApi.delete(`voucher/${id}`)
    },
    getVouchersByFilter(page, filter) {
        var path = page ? `voucher/filtered?page=${page}&per_page=10` : 'voucher/filtered'
        return paronApi.post(path, filter)
    }

}
